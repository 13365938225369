import { Modal } from "flowbite-react";

interface Props {
  show: boolean;
  success: boolean;
  closeAction: () => void;
}
export const InquiryResultModal = (props: Props) => {
  const { show, success, closeAction } = props;
  return (
    <Modal show={show} onClose={() => closeAction()}>
      <Modal.Header className=" text-my-blue">
        {success ? "Thank you for contacting us!" : "Contacting failed."}
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          {success ? (
            <p className="text-base leading-relaxed text-gray-500">
              We appreciate your interest in ARSOME Technology. We will get back
              in touch with you soon! Have a great day!
            </p>
          ) : (
            <p> mail sending failed.</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-center ">
        <button
          className="px-5 py-2 text-white rounded-md header_btn"
          onClick={() => closeAction()}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};
