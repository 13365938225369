import { Button, Label, Modal, Select, Spinner } from "flowbite-react";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, NavLink } from "react-router-dom";
export type contactFrm = {
  InputName: string;
  InputEmail: string;
  InputPhone: string;
  CompanyName: string;
  CompanyPhone: string;
  InputMessage: string;
};
export const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;

export const Contact = () => {
  const [isSending, setIsSending] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [ecapKey, setEcapKey] = useState<string | null>(null);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<contactFrm>();
  const sendEmail = async () => {
    // if (backend_baseUrl && ecapKey) {
    if (backend_baseUrl) {
      const data = getValues();
      const url = `${backend_baseUrl}/api/Contact`;
      const formData = new FormData();
      formData.append("InputName", data.InputName);
      formData.append("InputEmail", data.InputEmail);
      formData.append("CompanyPhone", data.CompanyPhone);
      formData.append("InputMessage", data.InputMessage);
      setIsSending(true);
      try {
        await fetch(url, {
          method: "POST",
          body: formData,
        });
      } catch (ex) {}
      setIsSending(false);
      setShowMessage(true);
    }
  };
  return (
    <div className="grid gap-y-6 xl:gap-y-8">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | ARSOME Technology</title>
        <meta
          name="description"
          content="We appreciate your interest in ARSOME Technology. We will get back
          in touch with you soon! Have a great day!"
        />
      </Helmet>
      <div className="max-md:block md:hidden">
        <div className="space-y-4 border-2 border-x-0 border-[#2E90F2] md:p-8 py-8 mt-8">
          <p className=" font-light text-[#383231] sm-w mx-auto">
            ARSOME (pronounced "awesome") Technology pioneered the tailor-made
            augmented and virtual reality industry about 10 years ago. Today,
            the most important and innovative organizations on the planet rely
            on our tailor-made training solutions, like the Department of
            Defense, U.S. Army, Travelers Insurance, and Pfizer
            Pharmaceuticals. At ARSOME Technology, our mission is to be the
            absolute best tailor-made AR/VR company on the planet
            so soldiers/employees will safely operate/maintain heavy machinery,
            vehicles, and complex machines. It’s the promise of ARSOME
            Technology to continue learning, inventing, and preparing our
            clients/partners for the next best immersive training solution;
            while achieving goals in an environment of fairness, honesty, and
            courtesy.
          </p>
        </div>
      </div>
      <section className="flex justify-center">
        <div
          className={`max-md:hidden md:meet_cover_bg_md xl:meet_cover_bg_xl md:flex justify-center md:py-3 md:items-center xl:py-0 md:h-[348px] xl:h-[600px]  md:w-full xl:items-end xl:place-content-between`}
        >
          <div
            className={`xl:w-[1100px] 2xl:w-[1440px] md:flex md:justify-center md:mx-auto xl:justify-start xl:ps-0`}
          >
            <div className="md:bg_workforce xl:ms-0 rounded-xl text-white p-8 md:p-4 xl:p-8 grid md:gap-4 xl:gap-8 md:w-[700px] xl:w-[800px] 2xl:w-[940px] max-md:mx-auto md:min-h-[251px]">
              <p className="2xl:text-lg">
                <b>ARSOME (pronounced "awesome") Technology</b> pioneered the tailor-made AR/VR industry about 
                10 years ago. Today, the most important organizations on the planet rely on our tailor-made training solutions, 
                like the U.S. Army, Travelers Insurance, Oshkosh Corporation, and Pfizer. At ARSOME Technology, our mission is 
                to be the absolute best tailor-made AR/VR company on the planet so soldiers/employees will safely operate/maintain heavy machinery, 
                vehicles, and complex machines. It’s our promise to continue learning, inventing, and preparing our 
                clients/partners for the next best immersive training solution; while achieving goals in an environment of fairness, honesty, and courtesy.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="flex items-center xl:min_contact_wrap md:min_md_contact_wrap"> */}
      <div className="flex flex-col justify-center">
        <section className="px-0 py-4 md:fit_wrap">
          <div className="flex justify-center">
            <div className="grid gap-3 md:grid-cols-2">
              <div className="flex items-center md:mx-3">
                <div className="md:w-[382px] xl:w-[487px] grid gap-y-6">
                  <h5 className="font-semibold xl:text-4xl md:text-2xl max-md:text-[#2E90F2] max-md:text-center max-md:text-xl max-md:font-bold max-md:px-3">
                    Have an opportunity for ARSOME to take part in?
                  </h5>
                  <h6 className="text-2xl font-bold max-md:text-xl max-md:text-center max-md:text-[#2E90F2] max-md:border max-md:border-b-0 max-md:border-x-0 max-md:border-[#2E90F2] max-md:px-3 max-md:pt-4">
                    Procurement, Sales & Partnerships
                  </h6>
                  <div className="flex max-md:flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      className="max-md:hidden"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M20 20.5H4C2.89543 20.5 2 19.6046 2 18.5V6.413C2.04661 5.34255 2.92853 4.49899 4 4.5H20C21.1046 4.5 22 5.39543 22 6.5V18.5C22 19.6046 21.1046 20.5 20 20.5ZM4 8.368V18.5H20V8.368L12 13.7L4 8.368ZM4.8 6.5L12 11.3L19.2 6.5H4.8Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      className="md:hidden max-md:mx-auto"
                      viewBox="0 0 25 24"
                      fill="none"
                    >
                      <path
                        d="M20.5 20H4.5C3.39543 20 2.5 19.1046 2.5 18V5.913C2.54661 4.84255 3.42853 3.99899 4.5 4H20.5C21.6046 4 22.5 4.89543 22.5 6V18C22.5 19.1046 21.6046 20 20.5 20ZM4.5 7.868V18H20.5V7.868L12.5 13.2L4.5 7.868ZM5.3 6L12.5 10.8L19.7 6H5.3Z"
                        fill="#2E90F2"
                      />
                    </svg>
                    <Link
                      className="ms-3 max-md:text-[#2E90F2] max-md:font-semibold max-md:text-sm max-md:text-center"
                      to="mailto:williams@arsome.com"
                    >
                      williams@arsome.com
                    </Link>
                  </div>
                  <div className="flex max-md:flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      className="max-md:hidden"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M8.74214 9.18189C7.66901 10.0937 7.27576 11.7567 8.20237 13.0908C9.06948 14.3392 10.1618 15.4313 11.4099 16.298C12.7441 17.2244 14.407 16.8311 15.3187 15.7579L15.3289 15.7626C16.4934 16.3021 17.7304 16.6718 19 16.8603V19.5L18.9989 19.5L18.9961 19.5C11.0215 19.5113 4.99148 13.4111 5.00001 5.50359V5.5H7.63937L7.63954 5.50117C7.828 6.77065 8.19761 8.00654 8.73714 9.17108L8.74214 9.18189ZM18.9989 21.5H20C20.5523 21.5 21 21.0523 21 20.5V15.9977C21 15.5021 20.637 15.0813 20.1468 15.0086L19.2926 14.8818C18.2123 14.7215 17.1605 14.4069 16.1695 13.9478L15.4166 13.5991C14.9804 13.397 14.462 13.534 14.1826 13.9253L13.8418 14.4024C13.5431 14.8206 12.9728 14.9484 12.5506 14.6552C11.4984 13.9245 10.5761 13.0024 9.84502 11.9499C9.55181 11.5277 9.67955 10.9574 10.0978 10.6587L10.5748 10.3181C10.9661 10.0386 11.1031 9.52021 10.901 9.0839L10.5518 8.33033C10.0927 7.3394 9.77823 6.28774 9.61786 5.20749L9.49104 4.35316C9.41827 3.86296 8.99745 3.5 8.50188 3.5H4.00001C3.44773 3.5 3.00001 3.94772 3.00001 4.5V5.50143C2.99029 14.5079 9.91023 21.5129 18.9989 21.5Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      className="md:hidden max-md:mx-auto"
                      fill="none"
                    >
                      <path
                        d="M9.24214 8.68189C8.16901 9.59373 7.77576 11.2567 8.70237 12.5908C9.56948 13.8392 10.6618 14.9313 11.9099 15.798C13.2441 16.7244 14.907 16.3311 15.8187 15.2579L15.8289 15.2626C16.9934 15.8021 18.2304 16.1718 19.5 16.3603V19L19.4989 19L19.4961 19C11.5215 19.0113 5.49148 12.9111 5.50001 5.00359V5H8.13937L8.13954 5.00117C8.328 6.27065 8.69761 7.50654 9.23714 8.67108L9.24214 8.68189ZM19.4989 21H20.5C21.0523 21 21.5 20.5523 21.5 20V15.4977C21.5 15.0021 21.137 14.5813 20.6468 14.5086L19.7926 14.3818C18.7123 14.2215 17.6605 13.9069 16.6695 13.4478L15.9166 13.0991C15.4804 12.897 14.962 13.034 14.6826 13.4253L14.3418 13.9024C14.0431 14.3206 13.4728 14.4484 13.0506 14.1552C11.9984 13.4245 11.0761 12.5024 10.345 11.4499C10.0518 11.0277 10.1796 10.4574 10.5978 10.1587L11.0748 9.81807C11.4661 9.53861 11.6031 9.02021 11.401 8.5839L11.0518 7.83033C10.5927 6.8394 10.2782 5.78774 10.1179 4.70749L9.99104 3.85316C9.91827 3.36296 9.49745 3 9.00188 3H4.50001C3.94773 3 3.50001 3.44772 3.50001 4V5.00143C3.49029 14.0079 10.4102 21.0129 19.4989 21Z"
                        fill="#2E90F2"
                      />
                    </svg>
                    <Link
                      className="ms-3 max-md:text-[#2E90F2] max-md:font-semibold max-md:text-sm max-md:text-center"
                      to="tel:8603047660"
                    >
                      (860-304-7660)
                    </Link>
                  </div>
                  <div className="flex max-md:flex-col">
                    <div className="flex items-center max-md:flex-col">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        className="max-md:hidden"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 21C10.7369 19.9227 9.56619 18.7416 8.5 17.4691C6.9 15.5581 5 12.712 5 10.0001C4.99858 7.1676 6.70425 4.61344 9.32107 3.52945C11.9379 2.44545 14.9501 3.04529 16.952 5.04905C18.2685 6.35966 19.0059 8.14244 19 10.0001C19 12.712 17.1 15.5581 15.5 17.4691C14.4338 18.7416 13.2631 19.9227 12 21ZM12 5.00005C9.23995 5.00336 7.00331 7.24 7 10.0001C7 11.166 7.527 13.1851 10.035 16.1861C10.6531 16.9241 11.309 17.6297 12 18.3001C12.691 17.6305 13.3472 16.9259 13.966 16.1891C16.473 13.1841 17 11.1651 17 10.0001C16.9967 7.24 14.7601 5.00336 12 5.00005ZM12 13.0001C10.3431 13.0001 9 11.6569 9 10.0001C9 8.3432 10.3431 7.00005 12 7.00005C13.6569 7.00005 15 8.3432 15 10.0001C15 10.7957 14.6839 11.5588 14.1213 12.1214C13.5587 12.684 12.7957 13.0001 12 13.0001Z"
                          fill="white"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        className="md:hidden max-md:mx-auto"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M12.5 20.9998C11.2369 19.9224 10.0662 18.7413 9 17.4688C7.4 15.5578 5.5 12.7118 5.5 9.99981C5.49858 7.16735 7.20425 4.6132 9.82107 3.5292C12.4379 2.44521 15.4501 3.04505 17.452 5.04881C18.7685 6.35941 19.5059 8.14219 19.5 9.99981C19.5 12.7118 17.6 15.5578 16 17.4688C14.9338 18.7413 13.7631 19.9224 12.5 20.9998ZM12.5 4.99981C9.73995 5.00311 7.50331 7.23975 7.5 9.99981C7.5 11.1658 8.027 13.1848 10.535 16.1858C11.1531 16.9239 11.809 17.6295 12.5 18.2998C13.191 17.6303 13.8472 16.9257 14.466 16.1888C16.973 13.1838 17.5 11.1648 17.5 9.99981C17.4967 7.23975 15.2601 5.00311 12.5 4.99981ZM12.5 12.9998C10.8431 12.9998 9.5 11.6567 9.5 9.99981C9.5 8.34295 10.8431 6.99981 12.5 6.99981C14.1569 6.99981 15.5 8.34295 15.5 9.99981C15.5 10.7955 15.1839 11.5585 14.6213 12.1211C14.0587 12.6837 13.2957 12.9998 12.5 12.9998Z"
                          fill="#2E90F2"
                        />
                      </svg>
                    </div>
                    <Link
                      className="ms-3 max-md:text-[#2E90F2] max-md:font-semibold max-md:text-sm max-md:text-center"
                      to="https://www.google.com/maps/place/ARSOME+Technology/@41.7716382,-72.5234072,17z/data=!3m1!4b1!4m15!1m8!3m7!1s0x89e65761e135efff:0x2b6a924316c2c6ba!2s769+Main+St,+Manchester,+CT+06040,+USA!3b1!8m2!3d41.7716342!4d-72.5208323!16s%2Fg%2F11c1yt590z!3m5!1s0x89e65771170cf41d:0x965c07e8715bb503!8m2!3d41.7716342!4d-72.5208323!16s%2Fg%2F11ghp04x6g?entry=ttu"
                      target={"_blank"}
                    >
                      ARSOME Technology 769 Main St, <br /> Manchester, CT 06040
                    </Link>
                  </div>
                  {/* <div className="flex">
                      <NavLink
                        to={'https://www.linkedin.com/company/arsome-tech/'}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M13 21.5H9V9.5H13V11.5C13.8526 10.4153 15.1456 9.76857 16.525 9.737C19.0056 9.75077 21.0072 11.7694 21 14.25V21.5H17V14.75C16.84 13.6326 15.8818 12.8036 14.753 12.806C14.2593 12.8216 13.7932 13.0378 13.4624 13.4046C13.1316 13.7715 12.9646 14.2573 13 14.75V21.5ZM7 21.5H3V9.5H7V21.5ZM5 7.5C3.89543 7.5 3 6.60457 3 5.5C3 4.39543 3.89543 3.5 5 3.5C6.10457 3.5 7 4.39543 7 5.5C7 6.03043 6.78929 6.53914 6.41421 6.91421C6.03914 7.28929 5.53043 7.5 5 7.5Z"
                            fill="white"
                          />
                        </svg>
                      </NavLink>
                      <NavLink
                        to={'https://www.youtube.com/@arsometechnology6916'}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="ms-3"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M12.005 19.512H11.985C11.923 19.512 5.72003 19.5 4.15503 19.075C3.29445 18.8432 2.62238 18.1707 2.39103 17.31C2.10961 15.7224 1.97371 14.1123 1.98503 12.5C1.97776 10.885 2.11733 9.27271 2.40203 7.683C2.63974 6.82151 3.30886 6.14595 4.16803 5.9C5.69003 5.5 11.722 5.5 11.978 5.5H11.999C12.062 5.5 18.281 5.512 19.83 5.937C20.6888 6.17001 21.3595 6.8411 21.592 7.7C21.8824 9.29354 22.0187 10.9113 21.999 12.531C22.006 14.144 21.8661 15.7543 21.581 17.342C21.3467 18.2016 20.6742 18.8726 19.814 19.105C18.294 19.508 12.261 19.512 12.005 19.512ZM10.005 9.505L10 15.505L15.212 12.505L10.005 9.505Z"
                            fill="white"
                          />
                        </svg>
                      </NavLink>
                    </div> */}
                  <div className="text-2xl font-bold max-md:text-xl max-md:text-[#2E90F2] max-md:text-center">
                    For Customer Support
                  </div>
                  <div className="flex max-md:flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      className="max-md:hidden"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M20 20.5H4C2.89543 20.5 2 19.6046 2 18.5V6.413C2.04661 5.34255 2.92853 4.49899 4 4.5H20C21.1046 4.5 22 5.39543 22 6.5V18.5C22 19.6046 21.1046 20.5 20 20.5ZM4 8.368V18.5H20V8.368L12 13.7L4 8.368ZM4.8 6.5L12 11.3L19.2 6.5H4.8Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      className="md:hidden max-md:mx-auto"
                      viewBox="0 0 25 24"
                      fill="none"
                    >
                      <path
                        d="M20.5 20H4.5C3.39543 20 2.5 19.1046 2.5 18V5.913C2.54661 4.84255 3.42853 3.99899 4.5 4H20.5C21.6046 4 22.5 4.89543 22.5 6V18C22.5 19.1046 21.6046 20 20.5 20ZM4.5 7.868V18H20.5V7.868L12.5 13.2L4.5 7.868ZM5.3 6L12.5 10.8L19.7 6H5.3Z"
                        fill="#2E90F2"
                      />
                    </svg>
                    <a
                      className="ms-3 max-md:text-[#2E90F2] max-md:font-semibold max-md:text-sm max-md:text-center"
                      href="mailto:info@arsome.com"
                    >
                      info@arsome.com
                    </a>
                  </div>
                </div>
              </div>
              <div className="xl:w-[542px] md:w-[342px] bg-white rounded-lg p-3 mx-3 max-md:border max-md:border-[#2E90F2] max-md:rounded-xl">
                <div className="grid gap-3">
                  <h6 className="text-base font-bold xl:text-2xl md:text-my-blue max-md:text-[#2E90F2] ">
                    Contact Information
                  </h6>
                  <h6 className=" text-my-gray">Program Selection</h6>
                  <div className="flex flex-wrap gap-2 xl:gap-3"></div>
                  <div>
                    <input
                      placeholder="Full Name*"
                      type="text"
                      className="w-full p-2 text-black border border-t-0 border-b-2 border-l-0 border-r-0 border-my-blue"
                      {...register("InputName", { required: true })}
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Phone Number (optional)"
                      type="text"
                      className="w-full p-2 text-black border border-t-0 border-b-2 border-l-0 border-r-0 border-my-blue"
                      {...register("CompanyPhone")}
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Email*"
                      type="text"
                      className="w-full p-2 text-black border border-t-0 border-b-2 border-l-0 border-r-0 border-my-blue"
                      {...register("InputEmail", { required: true })}
                    />
                  </div>
                  <div>
                    <div className="block mb-2">
                      <Label value="Note* (The minimum  length is 50)" />
                    </div>
                    <textarea
                      placeholder=""
                      rows={5}
                      className="w-full p-2 text-black border-2 rounded-md border-my-blue"
                      {...register("InputMessage", {
                        required: true,
                        minLength: 10,
                      })}
                    />
                  </div>
                  <div>
                    <ReCAPTCHA
                      sitekey="6Lf_n-MnAAAAAHc9ImWCA4oh4Btk8cid5m32bJAi"
                      onExpired={() => {
                        setEcapKey(null);
                      }}
                      onChange={(value) => {
                        setEcapKey(value);
                      }}
                    />
                  </div>
                  <div className="flex justify-end max-md:justify-center">
                    <button
                      className="px-5 py-2 rounded-md header_btn disabled:opacity-50 max-md:w-full max-md:text-white max-md:font-bold"
                      disabled={!isValid}
                      onClick={sendEmail}
                    >
                      {isSending && (
                        <Spinner
                          aria-label="Spinner button example"
                          className="me-2"
                        />
                      )}
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={showMessage}
        onClose={() => setShowMessage(false)}
        theme={{
          content: {
            base: "flex h-full w-full p-4 flex items-center md:h-auto",
            inner:
              "rounded-lg bg-white shadow dark:bg-gray-700 max-md:w-full md:w-[550px]",
          },
        }}
      >
        <Modal.Header className=" text-my-blue">
          Thank you for contacting us!
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500">
              We appreciate your interest in ARSOME Technology. We will get back
              in touch with you soon! Have a great day!
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-center ">
          <button
            className="px-5 py-2 text-white rounded-md header_btn"
            onClick={() => setShowMessage(false)}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
