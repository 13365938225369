interface Props {
  title: string;
  icon: string;
  icon_lg: string;
  description: string;
  onAction?: () => void;
}
export interface WhiteProps {
  title: string;
  isYellow?: boolean;
  disabled?: boolean;
  onAction?: (type: string) => void;
}
export const WhiteBtn = (props: WhiteProps) => {
  const { title, disabled, onAction, isYellow } = props;
  return (
    <button
      className={`transition hover:ease-linear hover:bg-[#383231] hover:text-white px-4 py-2 rounded-lg  text-[#383231] text-base ${
        disabled && " opacity-50"
      } ${isYellow ? "bg-[#FFD700] font-semibold" : "bg-white"}`}
      onClick={() => {
        if (onAction) {
          onAction(title);
        }
      }}
    >
      {title}
    </button>
  );
};

export const LearnMore = (props: Props) => {
  const { title, description, onAction, icon, icon_lg } = props;
  return (
    <div className="max-lg:learn_more_bg lg:border lg:border-[#2D8DED] p-4 rounded-xl grid flex-shrink-0 content-between w-[290px] md:w-[240px] md:min-h-[330px] xl:w-[439px] xl:min-h-[445px] xl:p-8 gap-y-4">
      <div>
        <div className="flex">
          <img
            src={icon}
            alt=""
            className="sm:h-[24px] lg:hidden lg:h-[64px]"
          />
          <img
            src={icon_lg}
            alt=""
            className="sm:h-[24px] max-lg:hidden lg:block lg:h-[64px]"
          />
        </div>
        <div className="space-y-3">
          <h6 className="font-bold md:text-sm xl:text-2xl ">{title}</h6>
          <p className="text-xs xl:text-base">{description}</p>
        </div>
      </div>
      <div className="flex justify-start">
        <button
          className={`font-bold px-4 py-2 rounded-lg max-lg:bg-white lg:blog_tab_selected lg:text-white   text-my-gray text-sm xl:text-base`}
          onClick={() => {
            if (onAction) {
              if (onAction) onAction();
            }
          }}
        >
          Learn More
        </button>
      </div>
    </div>
  );
};
