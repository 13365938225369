import { PropsWithChildren } from 'react';

type Props = React.HtmlHTMLAttributes<HTMLDivElement> &
  PropsWithChildren & { caption_cls?: string };
export const HeroWrap = (props: Props) => {
  const { className, children, caption_cls } = props;
  return (
    <section className="flex justify-center">
      <div
        className={`${className} md:flex flex justify-center md:py-3 md:items-center xl:py-10 xl:justify-center 2xl:h-[600px]  md:w-full`}
      >
        <div
          className={`xl:w-[1100px] 2xl:w-[1440px] ${
            !caption_cls ? 'flex justify-center mx-auto' : caption_cls
          } `}
        >
          <div
            className={
              'md:bg_workforce xl:ms-0 rounded-xl text-white p-8 md:p-4 xl:p-8 grid md:gap-4 xl:gap-8 md:w-[90vw] xl:w-[800px] 2xl:w-[880px] max-md:mx-auto '
            }
          >
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};
/**
 *
 */
