import ReactPlayer from 'react-player/youtube';
type Props = React.HtmlHTMLAttributes<HTMLDivElement> & {
  url: string;
};
export const VideoBox = (props: Props) => {
  const { url, className } = props;
  return (
    <div className={className}>
      <div className={`player-wrapper`}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          loop={true}
          controls={true}
          className={'react-player'}
        />
      </div>
    </div>
  );
};
