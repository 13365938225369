import { VideoBox } from "../components/VideoBox";

interface Props {
  title: string;
  icon: string;
  description: string;
  url: string;
  altText: string;
}
export const FitItem = (props: Props) => {
  const { title, description, icon, url, altText } = props;
  return (
    <div className="xl:w-[440px] md:w-[320px] shrink-0 snap-start">
      <div className="personalCards">
        <div className="flex justify-center md:justify-center">
          {url !== "" ? (
            <VideoBox
              className="w-[340px] md:w-[320px] xl:w-[440px] xl:min-h-[224px] md:min-h-[183px]"
              url={url}
            />
          ) : (
            <img
              src={icon}
              alt={altText}
              title={altText}
              className="w-[340px] md:w-[320px] xl:w-[440px] rounded-none max-md:rounded-md md:rounded-t-[20px] md:min-h-[200px]"
            />
          )}
        </div>
        <div className="flex justify-center pt-3 bg-white rounded-none rounded-b-[20px]">
          <div className="w-[340px] md:w-[277px] xl:w-[440px] xl:px-4 md:px-4 xl:h-[190px] md:h-[220px]">
            <h6 className="mb-3 text-sm font-bold text-center md:text-sm xl:text-xl text-[#383231] max-md:text-xl max-md:text-[#2E90F2]">
              {title}
            </h6>
            <p className="mb-3 text-xs text-center md:text-sm xl:text-base max-md:font-light max-md:text-base text-[#383231]">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export const FitItemV2 = (props: Props) => {
  const { title, description, icon, url } = props;
  return (
    <div className="xl:w-[700px] md:w-[500px] max-md:min-h-[390px] shrink-0 snap-start">
      <div className="personalCards_1">
        <div className="flex justify-center md:justify-center">
          {url !== "" ? (
            <VideoBox
              className="w-[340px] md:w-[500px] xl:w-[690px] xl:min-h-[354px] md:min-h-[283px]"
              url={url}
            />
          ) : (
            <img
              src={icon}
              alt=""
              className="w-[340px] md:w-[500px] xl:w-[690px] rounded-none max-md:rounded-md md:rounded-t-[20px] md:min-h-[283px]"
            />
          )}
        </div>
        <div className="flex justify-center pt-3 bg-white rounded-none rounded-b-[20px]">
          <div className="w-[340px] md:w-[500px] xl:w-[700px] xl:px-4 md:px-4 xl:h-[190px] md:h-[220px]">
            <h6 className="mb-3 text-xl font-semibold text-center text-[#383231] ">
              {title}
            </h6>
            <p className="mb-3 text-center xl:text-base font-light max-md:text-base text-[#383231]">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
