interface Props {
  title: string;
  selected: boolean;
  onClick: (val: string) => void;
}
export const OptionItem = (props: Props) => {
  const { title, selected, onClick } = props;
  return (
    <div
      className={`px-2 py-2 selection: text-sm xl:text-base rounded-md border cursor-pointer ${
        !selected
          ? 'bg-white border-1 border-my-gray text-my-gray'
          : ' bg-my-blue text-white border-1 border-my-blue'
      }`}
      onClick={() => onClick(title)}
    >
      {title}
    </div>
  );
};
